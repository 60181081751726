/* .slideshow-container {

  position: relative;
  margin: auto;
}

.slides {
  display: none;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  margin-right: -45px;
  margin-left: -45px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 3px 0 0 3px;

}

.next {
  right: 0;
  border-radius: 0 3px 3px 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.navigator {
  text-align: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #481238;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #7c3f69;
} */

.slideshow-container {
    position: relative;
    max-width: 100%;
    margin: auto;
  }
  
  .slides {
    display: none;
  }
  
  .slides.active {
    display: block;
  }
  
  .slideshow-containe img {
    width: 100%;
    height: auto;
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px 0 0 3px;
    margin-top: -22px;
    margin-right: -45px;
    margin-left: -45px;
  }
  
  .next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .navigator {
    text-align: center;
    padding: 10px 0;
  }
  
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #481238;
    border-radius: 50%;
    display: inline-block;
  }
  
  .dot.active {
    background-color: #7c3f69;
  }
  